<template>
	<NavigationBar />
	<q-page-container>
		<q-page padding>
			<div class="row">
				<div class="col-4"></div>
				<div class="col-4">
					<div class="q-pa-md">
						<q-form
							ref="userForm"
							@submit="onSubmit"
							class="q-gutter-md"
						>
							<div class="row">
								<div class="col-3">
									<q-avatar
										size="100px"
										font-size="52px"
										color="primary"
										text-color="white"
									>
										<q-icon
											v-if="
												this.avatarImageBase64 === null
											"
											name="account_circle"
										/>
										<img
											v-else
											:src="this.avatarImageBase64"
										/>
									</q-avatar>
								</div>
								<div class="col-9">
									<q-file
										v-model="this.userFormData.image"
										label="Choose new Image"
										filled
										:filter="checkFileType"
										@rejected="onRejected"
										@update:model-value="avatarFileOnUpdate"
									>
										<template v-slot:prepend>
											<q-icon name="attach_file" />
										</template>
									</q-file>
								</div>
							</div>

							<q-input
								filled
								type="text"
								v-model="userFormData.username"
								label="E-Mail / Username"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="userFormData.firstname"
								label="Firstname"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="userFormData.lastname"
								label="Lastname"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="userFormData.city"
								label="City"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-select
								filled
								v-model="userFormData.country"
								:options="userFormData.countries"
								:loading="this.loading"
								:disable="this.loading"
								label="Country"
							/>

							<q-select
								filled
								v-model="userFormData.language"
								:options="this.languages"
								:loading="this.loading"
								:disable="this.loading"
								label="Language"
							/>

							<div>
								<q-btn
									:loading="this.loading"
									:disable="this.loading"
									label="Save"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</div>
				</div>
				<div class="col-4"></div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import { useUserStore } from '../store/user';
	import { countries } from './../components/countries';
	import NavigationBar from '@/components/NavigationBar.vue';

	export default {
		name: 'Settings',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				countries: [],
				languages: [
					{ label: 'Deutsch', value: 'DE' },
					{ label: 'English', value: 'EN' },
				],
				userFormData: {
					username: '',
					firstname: '',
					lastname: '',
					city: '',
					country: '',
					language: '',
					image: null,
					imageBase64: '',
				},
				avatarImageBase64: null,
				loading: false,
			};
		},
		mounted() {
			// prepare countries select
			for (let i = 0; i < countries.length; i++) {
				this.countries.push({
					label: countries[i].name,
					value: countries[i].code,
				});
			}

			const userStore = useUserStore();
			userStore.getUser().then((response) => {
				this.userFormData.username = response.username;
				this.userFormData.firstname = response.firstname;
				this.userFormData.lastname = response.lastname;
				this.userFormData.city = response.city;
				for (let i = 0; i < this.countries.length; i++) {
					if (this.countries[i].value == response.country) {
						this.userFormData.country = this.countries[i];
					}
				}
				for (let i = 0; i < this.languages.length; i++) {
					if (this.languages[i].value == response.language) {
						this.userFormData.language = this.languages[i];
					}
				}
				if (response.image !== null) {
					this.avatarImageBase64 =
						'data:image/png;charset=utf-8;base64,' + response.image;
				}
			});
		},
		methods: {
			onSubmit() {
				this.$refs.userForm.validate().then((success) => {
					if (success) {
						this.loading = true;
						let data = {
							username: this.userFormData.username,
							firstname: this.userFormData.firstname,
							lastname: this.userFormData.lastname,
							city: this.userFormData.city,
							country: this.userFormData.country.value,
							language: this.userFormData.language.value,
							image: null,
						};

						if (this.userFormData.image !== null) {
							data.image = this.userFormData.imageBase64;
						}

						const userStore = useUserStore();
						userStore.updateUser(data).then(() => {
							this.loading = false;
							this.$router.push({ path: '/' });
						});
					}
				});
			},
			checkFileType(files) {
				return files.filter((file) => file.type === 'image/png');
			},
			onRejected(rejectedEntries) {
				// Notify plugin needs to be installed
				// https://quasar.dev/quasar-plugins/notify#Installation
				this.$q.notify({
					type: 'negative',
					message: `${rejectedEntries.length} file(s) did not pass validation constraints`,
				});
			},
			avatarFileOnUpdate() {
				const file = this.userFormData.image;
				var reader = new FileReader();
				reader.readAsDataURL(file);
				let that = this;
				reader.onload = function () {
					that.userFormData.imageBase64 = reader.result;
					//result = reader.result;
				};
				reader.onerror = function (error) {
					console.log('Error: ', error);
				};
			},
		},
	};
</script>

<style></style>
