import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_core_backend_url =
	process.env.VUE_APP_MANAGERPANEL_CORE_BACKEND_URL;

export const useUserStore = defineStore('user', {
	state: () => ({
		user: null,
		organisations: [],
		modules: [],
	}),
	actions: {
		async getUser() {
			if (this.user == null) {
				const auth = useAuthStore();
				const jwt = auth.jwt;
				const url =
					managerpanel_core_backend_url + 'users/getCurrentUser';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt.jwt,
						},
					})
					.then((response) => {
						that.user = response.data;
					});
			}
			return this.user;
		},
		async getUserModules() {
			if (this.modules.length == 0) {
				const auth = useAuthStore();
				const jwt = auth.jwt;

				const url = managerpanel_core_backend_url + 'users/modules';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt.jwt,
						},
					})
					.then((response) => {
						that.modules = response.data;
					});
			}
			return this.modules;
		},
		async updateUser(data) {
			this.user.username = data.username;
			this.user.firstname = data.firstname;
			this.user.lastname = data.lastname;
			this.user.city = data.city;
			this.user.country = data.country;
			this.user.language = data.language;
			if (data.image !== undefined && data.image !== null) {
				this.user.image = data.image;
			}

			let user = this.user;
			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url = managerpanel_core_backend_url + 'users/edit';
			let that = this;
			await axios
				.put(url, JSON.stringify(user), {
					headers: {
						Authorization: jwt.jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					that.user = response.data;
				});

			return user;
		},
		async getOrganisations() {
			if (this.organisations.length == 0) {
				const auth = useAuthStore();
				const jwt = auth.jwt;

				const url = managerpanel_core_backend_url + 'organisations';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt.jwt,
						},
					})
					.then((response) => {
						that.organisations = response.data;
					});
			}
			return this.organisations;
		},
		async updateOrganisation(data) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_core_backend_url + 'organisations/' + data.id;
			let that = this;
			await axios
				.put(url, data, {
					headers: {
						Authorization: jwt.jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					that.organisations[0] = response.data;
				});

			return this.organisations[0];
		},
	},
});
