<template>
	<NavigationBar />
	<q-page-container>
		<q-page> </q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';

	export default {
		name: 'Home',
		data() {
			return {};
		},
		components: {
			NavigationBar: NavigationBar,
		},
	};
</script>

<style></style>
