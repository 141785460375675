<template>
	<q-header elevated class="bg-primary text-white">
		<q-toolbar class="bg-primary text-white">
			<q-btn flat round dense icon="apps" class="q-mr-sm">
				<q-menu>
					<div class="row q-col-gutter-xs" style="margin-top: 1rem">
						<div
							class="col-6"
							v-for="module in this.modules"
							:key="module.id"
							style="text-align: center"
						>
							<router-link
								:to="{
									name: 'Module',
									params: { moduleId: module.id },
								}"
							>
								<img src="https://via.placeholder.com/50" />
								<p>{{ module.title }}</p>
							</router-link>
						</div>
					</div>
				</q-menu>
			</q-btn>

			<q-toolbar-title></q-toolbar-title>

			<q-avatar>
				<q-icon
					v-if="this.avatarImageBase64 === null"
					name="account_circle"
				/>
				<img v-else :src="this.avatarImageBase64" />
				<q-menu>
					<q-list style="min-width: 100px">
						<q-item clickable v-close-popup>
							<q-item-section
								><router-link to="/settings"
									>Persönliche Einstellungen</router-link
								></q-item-section
							>
						</q-item>
						<q-item clickable v-close-popup>
							<q-item-section
								><router-link to="/organisations/settings"
									>Organisationseinstellungen</router-link
								></q-item-section
							>
						</q-item>
						<q-separator />
						<q-item clickable v-close-popup>
							<q-item-section>Logout</q-item-section>
						</q-item>
					</q-list>
				</q-menu>
			</q-avatar>
		</q-toolbar>
	</q-header>
</template>

<script>
	import { useUserStore } from '../store/user';

	export default {
		name: 'NavigationBar',
		data() {
			return {
				modules: [],
				avatarImageBase64: null,
			};
		},
		mounted() {
			const userStore = useUserStore();
			userStore.getUserModules().then((response) => {
				this.modules = response;
			});
			userStore.getUser().then((response) => {
				if (response.image !== null) {
					this.avatarImageBase64 =
						'data:image/png;charset=utf-8;base64,' + response.image;
				}
			});
		},
		methods: {
			openMenu() {
				if (this.$refs.userMenu.getState() == true) {
					this.$refs.userMenu.close();
				} else {
					this.$refs.userMenu.open();
				}
			},
			openModuleMenu() {
				if (this.$refs.moduleMenu.getState() == true) {
					this.$refs.moduleMenu.close();
				} else {
					this.$refs.moduleMenu.open();
				}
			},
			logout() {
				const userStore = useUserStore();
				userStore.removeJwtToken();
				this.$router.push({ path: '/login' });
			},
		},
	};
</script>
