import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Settings from '@/views/Settings.vue';
import OrganisationsSettings from '@/views/OrganisationsSettings.vue';
import Module from '@/views/Module.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
	},
	{
		path: '/organisations/settings',
		name: 'OrganisationsSettings',
		component: OrganisationsSettings,
	},
	{
		path: '/module/:moduleId',
		name: 'Module',
		component: Module,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
