<template>
	<NavigationBar />
	<q-page-container v-if="moduleLink != null">
		<q-page>
			<iframe
				ref="moduleIframe"
				:src="moduleLink"
				frameborder="0"
				class="full-height full-width absolute"
				id="moduleiframe"
			>
				<p>
					Ihr Browser kann leider keine eingebetteten Frames anzeigen:
					Sie können die eingebettete Seite über den folgenden Verweis
					aufrufen:
					<a href="https://wiki.selfhtml.org/wiki/Startseite"
						>SELFHTML</a
					>
				</p>
			</iframe>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';
	import { useUserStore } from '../store/user';
	import { useAuthStore } from '../store/auth';

	export default {
		name: 'Module',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				currentModule: {
					entry_url: '',
				},
				moduleLink: null,
				authStore: null,
			};
		},
		mounted() {
			const moduleId = this.$route.params.moduleId;
			this.updateCurrentModule(moduleId);

			// update every time if jwt was updated
			const authStore = useAuthStore();
			let that = this;
			authStore.$subscribe((mutation, state) => {
				if (that.currentModule != null) {
					let iframe = document.getElementById('moduleiframe');
					iframe.contentWindow.postMessage(
						{
							jwt: state.jwt.jwt,
							expiration: state.jwt.expiration,
						},
						that.currentModule.entry_url
					);
				}
			});
		},
		watch: {
			'$route.params.moduleId': function () {
				const moduleId = this.$route.params.moduleId;
				this.updateCurrentModule(moduleId);
				console.log('bla');
			},
		},
		methods: {
			updateCurrentModule(moduleId) {
				let currentModule = null;
				this.authStore = useAuthStore();

				let that = this;
				const userStore = useUserStore();
				userStore.getUserModules().then((response) => {
					response.forEach(function (value) {
						if (value.id == moduleId) {
							currentModule = value;
						}
					});

					this.currentModule = currentModule;

					if (this.currentModule != null) {
						let link = null;
						const entry_url = this.currentModule.entry_url;
						const authStore = useAuthStore();
						const jwt = authStore.jwt;

						if (entry_url != '' && jwt.jwt != '') {
							const linkUrl = new URL(entry_url);
							linkUrl.searchParams.append('jwt', jwt.jwt);
							link = linkUrl.href;
							that.moduleLink = link;
						}
					}
				});
			},
		},
	};
</script>

<style></style>
