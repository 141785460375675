<template>
	<q-page-container>
		<q-page>
			<div class="row">
				<div class="col-6">
					<div class="q-pa-md">
						<q-form
							ref="userForm"
							@submit="onSubmit"
							class="q-gutter-md"
						>
							<q-input
								filled
								type="text"
								v-model="loginFormData.username"
								label="E-Mail / Username"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="loginFormData.password"
								label="Firstname"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<div>
								<q-btn
									:loading="this.loading"
									:disable="this.loading"
									label="Login"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</div>
				</div>
				<div class="col-6">
					<img
						class="object-cover w-full h-screen hidden md:block"
						src="https://source.unsplash.com/IXUM4cJynP0"
					/>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import { useAuthStore } from '../store/auth';

	export default {
		name: 'Login',
		data() {
			return {
				loginFormData: {
					username: '',
					password: '',
				},
				loading: false,
			};
		},
		methods: {
			onSubmit() {
				this.$refs.userForm.validate().then((success) => {
					if (success) {
						let username = this.loginFormData.username;
						let password = this.loginFormData.password;

						const managerpanel_core_backend_url =
							process.env.VUE_APP_MANAGERPANEL_CORE_BACKEND_URL;
						const url =
							managerpanel_core_backend_url + 'auth/login';

						this.loading = true;

						this.axios
							.post(
								url,
								{
									username: username,
									password: password,
								},
								{ withCredentials: true }
							)
							.then((response) => {
								this.loading = false;
								if (response.status == 200) {
									let jwt = response.data;
									const authStore = useAuthStore();
									authStore.setJwtToken(jwt.token);
									this.$router.push({ path: '/' });
								}
							})
							.catch((error) => {
								this.loading = false;
								console.log(error);
								this.$q.notify({
									type: 'negative',
									message: `Wrong credentials`,
								});
							});
					}
				});
			},
		},
	};
</script>

<style></style>
