import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';

import { createPinia } from 'pinia';
const pinia = createPinia();
import { useAuthStore } from './store/auth';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

router.beforeEach((to, from, next) => {
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const authStore = useAuthStore();

	let currentJwt = authStore.jwt;

	if (authRequired == false || currentJwt != null) {
		next();
	} else {
		authStore.refreshJwt().then(() => {
			currentJwt = authStore.jwt;
			if (currentJwt != null) {
				next();
			} else {
				next('/login');
			}
		});
	}
});

createApp(App)
	.use(Quasar, quasarUserOptions)
	.use(pinia)
	.use(router)
	.use(VueAxios, axios)
	.mount('#app');
