<template>
	<NavigationBar />
	<q-page-container>
		<q-page padding>
			<div class="row">
				<div class="col-4"></div>
				<div class="col-4">
					<div class="q-pa-md">
						<q-form
							ref="organisationForm"
							@submit="onSubmit"
							class="q-gutter-md"
						>
							<q-input
								filled
								type="text"
								v-model="organisationFormData.title"
								label="Title"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.ustid"
								label="UST ID"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.taxnumber"
								label="Steuernummer"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.bank"
								label="Bank"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.iban"
								label="IBAN"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.bic"
								label="BIC"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.bankowner"
								label="Bankowner"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.taxoffice"
								label="Taxoffice"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.website"
								label="Website"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="text"
								v-model="organisationFormData.owner"
								label="Owner"
								:loading="this.loading"
								:disable="this.loading"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<div>
								<q-btn
									:loading="this.loading"
									:disable="this.loading"
									label="Save"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</div>
				</div>
				<div class="col-4"></div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';
	import { useUserStore } from '../store/user';

	export default {
		name: 'OrganisationsSettings',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				organisationFormData: {
					id: '',
					title: '',
					type: '',
					ustid: '',
					taxnumber: '',
					bank: '',
					iban: '',
					bic: '',
					bankowner: '',
					taxoffice: '',
					website: '',
					owner: '',
				},
				loading: false,
			};
		},
		mounted() {
			const userStore = useUserStore();
			userStore.getOrganisations().then((response) => {
				this.organisationFormData = response[0];
			});
		},
		methods: {
			updateOrganistion() {
				this.buttonLoading = true;

				userStore.updateOrganistion(this.organisation).then(() => {
					this.buttonLoading = false;
					this.$router.push({ path: '/' });
				});
			},
			onSubmit() {
				this.$refs.organisationForm.validate().then((success) => {
					if (success) {
						this.loading = true;

						const userStore = useUserStore();
						userStore
							.updateOrganisation(this.organisationFormData)
							.then(() => {
								this.loading = false;
								this.$router.push({ path: '/' });
							});
					}
				});
			},
		},
	};
</script>

<style></style>
